import { getSessionId, logoff as myadminLogout } from './myAdminService';
import { httpDelete, httpPost } from './apiService';
import { deleteCookie, getCookie, setCookie } from './cookieService';
import { devUserInfoObject } from '@models/user';
import { CookieKey } from '@models/cookie';

export const login = async (myAdminBaseApiUrl, myAdminSource, userData, googleAccessToken) => {
    setCookie(CookieKey.AdminUserInfoKey, JSON.stringify(userData), 1);
    let myAdminSessionId = await getSessionId(myAdminBaseApiUrl, myAdminSource, userData.email, googleAccessToken);
    let myAdminUser = await cmsLogin(myAdminSessionId);

    return myAdminUser;
};

export async function sendFailedLogin(username) {
    return await httpPost(`/api/authentication/failed-login/${username}`);
}

export async function logout(myAdminBaseApiUrl, sessionId) {
    try {
        await cmsLogout();
    } catch (error) {
        console.warn(`Error logging out of local API: ${error.message}`);
    }

    try {
        await myadminLogout(myAdminBaseApiUrl, sessionId);
    } catch (error) {
        console.warn(`Error logging out of MyAdmin: ${error.message}`);
    }

    deleteCookie(CookieKey.AdminUserInfoKey);
}

export async function cmsLogout() {
    await httpDelete('/api/authentication/');
}

async function cmsLogin(myAdminSessionId) {
    try {
        let myAdminUser = (await httpPost(`/api/authentication/${myAdminSessionId}`)).json();
        return myAdminUser;
    } catch (error) {
        console.error('Error logging into CMS with MyAdmin session Id');
        console.error(error);

        if (error.message && error.message.includes('401')) {
            throw new Error('Unauthorized access. Please verify your roles in this environment.');
        }

        throw new Error('Failed to log in to the CMS. Please contact an administrator for assistance.');
    }
}

export function getUserInfo() {
    const userInfo = typeof document !== 'undefined' ? getCookie(CookieKey.AdminUserInfoKey) : '';

    return userInfo === '' ? devUserInfoObject : JSON.parse(userInfo);
}
