import { CMSLink } from '@components/CMSLink';
import { AdminLogoutIcon } from '@icon/AdminLogoutIcon';
import { LayoutDashboardIcon } from '@icon/LayoutDashboardIcon';
import { HistoryIcon } from '@icon/HistoryIcon';
import { logout } from '@services/authenticationPublicService';
import { useAtom, useSetAtom } from 'jotai';
import { logoutAtom, myAdminUserAtom } from '@atoms/authentication';
import { useStaticValues } from '@stateManagement/StaticValuesContext';
import React from 'react';
import { isEditingAtom, isSideNavbarVisibleAtom } from '@atoms/admin';
import { UserMenuOption } from '@models/adminUserMenu';
import { logError } from '@helpers/errors';

const classes = {
    menuOptionIcon: {
        verticalAlign: 'text-top',
        marginRight: '1rem',
        height: '1em',
        width: '1em',
    },
};

interface AdminNavDropdownOptionsProps {
    optionKey: UserMenuOption;
    open: boolean;
}

export function AdminNavDropdownOptions({ optionKey, open }: AdminNavDropdownOptionsProps): JSX.Element {
    const { myAdminBaseApiUrl } = useStaticValues();
    const setEditing = useSetAtom(isEditingAtom);
    const setSideNavbarVisible = useSetAtom(isSideNavbarVisibleAtom);
    const [myAdminUser] = useAtom(myAdminUserAtom);
    const [, userLogout] = useAtom(logoutAtom);

    const handleLogout = (): void => {
        logout(myAdminBaseApiUrl, myAdminUser && myAdminUser.sessionId)
            .then(() => {
                userLogout();
                setEditing(false);
                setSideNavbarVisible(false);
            })
            .then(() => (window.location.pathname = '/'))
            .catch((error) => logError(error, 'logging out'));
    };

    const dropdownOptions = {
        [UserMenuOption.Dashboard]: (
            <CMSLink href='/admin/dashboard' tabIndex={open ? 0 : -1} spaLink>
                <LayoutDashboardIcon css={classes.menuOptionIcon} />
                Admin dashboard
            </CMSLink>
        ),
        [UserMenuOption.Logout]: (
            <button onClick={handleLogout} tabIndex={open ? 0 : -1}>
                <AdminLogoutIcon css={classes.menuOptionIcon} />
                Logout
            </button>
        ),
        [UserMenuOption.History]: (
            <CMSLink href='/admin/history' tabIndex={open ? 0 : -1} spaLink>
                <HistoryIcon css={classes.menuOptionIcon} />
                History
            </CMSLink>
        ),
    };

    return dropdownOptions[optionKey];
}
