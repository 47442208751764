import { httpPost, httpDelete } from './apiService';

export async function getSessionId(myAdminBaseApiUrl, myAdminSource, userName, refreshToken) {
    let requestObject = JSON.stringify({ userName, refreshToken, source: myAdminSource });
    let response = await httpPost(`${myAdminBaseApiUrl}/Authenticate/OauthRefreshToken`, requestObject);
    var sessionId = await response.json();

    return validateSessionId(sessionId);
}

export async function logoff(myAdminBaseApiUrl, sessionId) {
    let headers = { 'Auth-SessionId': sessionId };
    await httpDelete(`${myAdminBaseApiUrl}/Authenticate/LogOff/${sessionId}`, headers);
}

function validateSessionId(sessionId) {
    var guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (!sessionId || !sessionId.match(guidRegex)) {
        console.warn('Invalid session ID returned.');
        throw new Error('Login failed.');
    }

    return sessionId;
}
