import React from 'react';

export function HistoryIcon({ ...other }: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...other}
        >
            <path
                d='M13.3018 6.95365C13.3018 3.21158 9.65893 0.915549 6.57767 1.34775C3.49516 1.78012 1.15142 4.29032 0.958285 7.34877C0.76764 10.3678 3.1532 13.3396 6.60061 13.9832C6.85778 14.0312 7.02732 14.2785 6.9793 14.5356C6.93127 14.7926 6.68387 14.9621 6.42671 14.9141C2.56785 14.1938 -0.211654 10.8436 0.0128029 7.28911C0.23477 3.77406 2.92592 0.903634 6.44603 0.40988C9.96739 -0.0840484 14.2492 2.51243 14.2492 6.95365C14.2492 7.21517 14.0371 7.42717 13.7755 7.42717C13.5139 7.42717 13.3018 7.21517 13.3018 6.95365Z'
                fill='currentColor'
            />
            <path
                d='M6.61143 4.08665C6.87304 4.08665 7.08512 4.29865 7.08512 4.56018V8.17843H9.34018C9.60178 8.17843 9.81386 8.39043 9.81386 8.65195C9.81386 8.91348 9.60178 9.12548 9.34018 9.12548H6.61143C6.34983 9.12548 6.13775 8.91348 6.13775 8.65195V4.56018C6.13775 4.29865 6.34983 4.08665 6.61143 4.08665Z'
                fill='currentColor'
            />
            <path
                d='M18.0001 10.6944C18.0001 10.4329 17.788 10.2209 17.5264 10.2209C17.2648 10.2209 17.0527 10.4329 17.0527 10.6944V11.2462C16.265 9.94053 14.7965 9.11047 13.175 9.20918C10.8952 9.34798 9.13826 11.2727 9.20818 13.555C9.27809 15.8373 11.1495 17.6509 13.4336 17.65C13.6952 17.6499 13.9072 17.4378 13.9071 17.1763C13.907 16.9148 13.6948 16.7029 13.4332 16.703C11.6612 16.7037 10.2093 15.2966 10.1551 13.526C10.1009 11.7554 11.4639 10.2622 13.2326 10.1545C14.8227 10.0577 16.2232 11.1121 16.607 12.6078H15.1388C14.8771 12.6078 14.6651 12.8198 14.6651 13.0813C14.6651 13.3428 14.8771 13.5548 15.1388 13.5548H17.1109C17.1436 13.5588 17.1773 13.5594 17.2114 13.5563L17.2251 13.5548H17.5264C17.788 13.5548 18.0001 13.3428 18.0001 13.0813V10.6944Z'
                fill='currentColor'
            />
        </svg>
    );
}
