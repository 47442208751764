import React, { useState, useRef, useEffect } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { getUserInfo } from '@services/authenticationPublicService';
import { UserDropdownIcon } from '@components/IconComponents/UserDropdownIcon';
import { useAccessiblePopout } from '@web-for-marketing/react-ui';
import { userMenuDropdownStyles as classes } from './styleObjects/userMenuDropdownStyles';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { AdminNavDropdownOptions } from './AdminNavDropdownOptions';
import { UserMenuOption } from '@models/adminUserMenu';
import { AuthenticatedUserInfo } from '@models/user';
import { Text } from '@web-for-marketing/react-ui';
import { ClassNames } from '@emotion/react';

export type DropdownSize = 'regular' | 'small';

interface UserMenuDropdownProps {
    size?: DropdownSize;
    dropdownOptions: UserMenuOption[];
}

export function UserMenuDropdown({ size = 'regular', dropdownOptions }: UserMenuDropdownProps): JSX.Element | null {
    const [isMobile] = useAtom(isMobileAtom);
    const [open, setOpen] = useState(false);
    const menu = useRef(null);
    const close = (): void => setOpen(false);
    const toggleOpen = (): void => setOpen((curValue) => !curValue);
    useAccessiblePopout(menu, open, close, { disableScroll: false });
    const [userInfo, setUserInfo] = useState<AuthenticatedUserInfo | null>(null);

    useEffect(() => {
        setUserInfo(getUserInfo());
    }, []);

    return userInfo !== null ? (
        <>
            <div css={[classes.navItemContainer, classes.menuContainer(size)]} ref={menu}>
                <ClassNames>
                    {({ css }) => (
                        <Tooltip
                            title={
                                !open ? (
                                    <>
                                        {' '}
                                        {userInfo.fullName}
                                        <br />
                                        <i>Click for options</i>{' '}
                                    </>
                                ) : (
                                    ''
                                )
                            }
                            classes={{ tooltip: css(classes.tooltip) }}
                            placement='bottom'
                            enterDelay={1000}
                        >
                            <button
                                css={classes.userButton(size)}
                                onClick={toggleOpen}
                                aria-label={open ? 'Close user options dropdown' : 'Open user options dropdown'}
                                data-testid='userMenuDropdownButton'
                            >
                                {userInfo.imageUrl ? (
                                    <img
                                        css={[classes.userIcon, classes.imageIcon(size)]}
                                        src={userInfo.imageUrl}
                                        alt='User profile'
                                        data-testid='userMenuImageIcon'
                                    />
                                ) : (
                                    <div css={[classes.userIcon, classes.textIcon]} data-testid='userMenuTextIcon'>
                                        {userInfo.firstName[0]}
                                    </div>
                                )}
                                <Text variant={size === 'small' ? 'body2' : 'body1'} color='tertiary'>
                                    {isMobile ? userInfo.firstName : userInfo.fullName}
                                </Text>
                                <UserDropdownIcon css={[classes.dropdownIcon, open ? classes.rotate : '']} />
                            </button>
                        </Tooltip>
                    )}
                </ClassNames>
                <Grid
                    container
                    component='nav'
                    css={[classes.menuOptions(size), open ? classes.menuOpen(size) : '']}
                    data-testid='userMenuOptions'
                    aria-hidden={!open}
                >
                    {dropdownOptions.map((option) => (
                        <AdminNavDropdownOptions key={option} optionKey={option} open={open} />
                    ))}
                </Grid>
            </div>
            {open ? (
                <div aria-hidden onClick={close} css={classes.background} data-testid='userMenuBackground' />
            ) : null}
        </>
    ) : null;
}
