export interface AuthenticatedUserInfo {
    firstName: string;
    lastName: string;
    fullName: string;
    imageUrl: string;
    email: string;
}

export const devUserInfoObject: AuthenticatedUserInfo = {
    firstName: 'WFM Dev',
    lastName: 'Member',
    fullName: 'WFM Dev Member',
    imageUrl:
        'https://lh3.googleusercontent.com/fife/ABSRlIpbeJ2vSuu-ULG-hRooWXRk9cIFDOWlQ4rdepzJAsaQD_lGXJj-IHHQ6ylm1ObCa12ls4qxN5HrZAkAx_8Clmj_ym2VrzWREebbF8cIu2OmDa4-b04KHzTB0kb2e4Xd5M1dUlF1a58bQuVYoOAM4pwHV-qlqTz8gX0fZLEwTgMFmi5GTSmFRHwOFR3flYn-yjX0-VvbhZOFx7MMpFGpSFsIjO7u7mznnxhlcCacHYk8ZwaLhczBTRuKwbsZhkIsJX8rBZkVCVdWDE7XnA3Vc6r7mqUHXO6yfoX_NxEMcGdFl8OlsPS3buemEzKfqPE6nXpn5oTQGMJsnl6_C2eWhgFkFJi44HWUTJ3X2QHr6lkF3NfjAefuQlE96zeNS5elKg3HN-YAm0gi32emAH_2_s8UjKTJcVcG9ZnwUV1p7fn_kO1YY3p8nu6WCgLLdBmbF-Pk4kSbGrgiHZDvF7cXVAd0iHF3OMEM2Q9_p2_oKZg0TfbgzTfumnvJJhhljWEq-IvuX6svKUMO9MSmZcHm862iq41s6EZ5fntm1dIALAW60Tczrt-S9SoIEE7DAS3X3vqYxEwurOCIZtMhPiuCE1917PdSWLgNhp9qWEKiE3XZzKG5k7YAik0H75eC03nXODWIWWGaM0rT9GTodvwjJdcw1lE3tHY-6eKQaehGSEa2whz-2XkG5_epnpIhvUFxUjvFrNm4oD7X8MDskJzGf2M39wY7SzQ6oG8=w1920-h906-ft',
    email: 'wfmdevmember@geotab.com',
};
