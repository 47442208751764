import React from 'react';

export function AdminLogoutIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='1em'
            height='1em'
            viewBox='0 0 19 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='M18.1487 10.0978H9.14868' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
            <path
                d='M15.1487 13.1304L18.1487 10.0978L15.1487 7.06522'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.64868 2.89536H13.2737C13.4808 2.89536 13.6487 3.06507 13.6487 3.27443V5.16981'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.6487 15.0258V16.9212C13.6487 17.1305 13.4808 17.3002 13.2737 17.3002H7.64868'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.898682 17.0902C0.898751 17.268 1.02099 17.4218 1.19268 17.4602L7.19268 18.8075C7.30366 18.8323 7.41978 18.8049 7.50841 18.733C7.59705 18.661 7.64864 18.5524 7.64868 18.4375V1.75814C7.64864 1.64325 7.59705 1.53458 7.50841 1.46266C7.41978 1.39075 7.30366 1.36335 7.19268 1.38816L1.19268 2.73616C1.02099 2.77455 0.898751 2.92838 0.898682 3.10614V17.0902Z'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.02368 11.235C5.645 11.235 6.14868 10.7259 6.14868 10.0978C6.14868 9.46973 5.645 8.96057 5.02368 8.96057C4.40236 8.96057 3.89868 9.46973 3.89868 10.0978C3.89868 10.7259 4.40236 11.235 5.02368 11.235Z'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
