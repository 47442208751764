import React from 'react';

export function UserDropdownIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='1em'
            height='1em'
            viewBox='0 0 11 6'
            stroke='#C1C9D6'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='M1 1.09784L5.5 5.09784L10 1.09784' />
        </svg>
    );
}
