import { atom } from 'jotai';
import { getLanguageByCode } from '@helpers/languages';
import { LanguageCode } from '@models/languageCode';

export const isEditorExpandedAtom = atom(false);

export const isNavbarExpandedAtom = atom(false);

export const enableNavbarExpansionAtom = atom(true);

export const adminLanguageAtom = atom(getLanguageByCode(LanguageCode.en));

export const isEditingAtom = atom(false);

export const isSideNavbarVisibleAtom = atom(true);
