import React from 'react';

export function LayoutDashboardIcon({ ...other }: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            aria-hidden
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            stroke='currentColor'
            {...other}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.375 14.375C1.375 13.8227 1.82272 13.375 2.375 13.375H7.875C8.42728 13.375 8.875 13.8227 8.875 14.375V17.625C8.875 18.1773 8.42728 18.625 7.875 18.625H2.375C1.82272 18.625 1.375 18.1773 1.375 17.625V14.375Z'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.625 17.625C18.625 18.1773 18.1773 18.625 17.625 18.625H12.125C11.5727 18.625 11.125 18.1773 11.125 17.625V9.875C11.125 9.32272 11.5727 8.875 12.125 8.875H17.625C18.1773 8.875 18.625 9.32272 18.625 9.875V17.625Z'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.625 5.625C18.625 6.17728 18.1773 6.625 17.625 6.625L12.125 6.625C11.5727 6.625 11.125 6.17728 11.125 5.625V2.375C11.125 1.82272 11.5727 1.375 12.125 1.375L17.625 1.375C18.1773 1.375 18.625 1.82272 18.625 2.375V5.625Z'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.375 2.375C1.375 1.82272 1.82272 1.375 2.375 1.375H7.875C8.42728 1.375 8.875 1.82272 8.875 2.375V10.125C8.875 10.6773 8.42728 11.125 7.875 11.125H2.375C1.82272 11.125 1.375 10.6773 1.375 10.125V2.375Z'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
